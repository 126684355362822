<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <div class="center-content-v3">
        <div class="form-verify-v3">
          <p class="mb-4 title-form-v3 text-center">New password created successfully</p>
          <p class="mt-2 text-verify-v3 sub-title-form-v3">You can now use your new password to sign in again.</p>
          <button @click="toLoginPage()" class="mt-2 forgot-password-btn-v3">Sign In</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";

export default {
  name: 'ChangePasswordMsg',
  components: {
    Navbar
  },
  methods: {
    toLoginPage() {
      this.$router.push('/login')
    }
  }
}
</script>

<style>

</style>
